/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative;
}
.ant-tree.ant-tree-directory .ant-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  content: "";
  pointer-events: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: #f5f5f5;
}
.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-tree.ant-tree-directory
  .ant-tree-treenode
  .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory
  .ant-tree-treenode
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #1890ff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory
  .ant-tree-treenode-selected
  .ant-tree-node-content-wrapper {
  color: #fff;
  background: transparent;
}
.ant-tree-checkbox {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #1890ff;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: "";
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),
    opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: " ";
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: " ";
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked
  .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
  animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
  animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: " ";
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled
  .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  background: #fff;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.ant-tree-rtl {
  direction: rtl;
}
.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #e6f7ff;
}
.ant-tree-list-holder-inner {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.ant-tree.ant-tree-block-node
  .ant-tree-list-holder-inner
  .ant-tree-node-content-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}
.ant-tree .ant-tree-treenode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-tree .ant-tree-treenode-rtl {
  direction: rtl;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-tree-indent {
  -ms-flex-item-align: stretch;
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-tree .ant-tree-switcher {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}
.ant-tree .ant-tree-switcher .ant-tree-switcher-icon,
.ant-tree .ant-tree-switcher .ant-select-tree-switcher-icon {
  font-size: 10px;
  display: inline-block;
  font-weight: bold;
}
.ant-tree .ant-tree-switcher .ant-tree-switcher-icon svg,
.ant-tree .ant-tree-switcher .ant-select-tree-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree .ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.ant-tree-rtl.ant-tree .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.ant-tree .ant-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-tree .ant-tree-node-content-wrapper {
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #bae7ff;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none;
}
.ant-tree .ant-tree-treenode-loading .ant-tree-iconEle {
  display: none;
}
.ant-tree-node-content-wrapper[draggable="true"] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-tree .ant-tree-treenode.drag-over > [draggable] {
  color: white;
  background-color: #1890ff;
  opacity: 0.8;
}
.ant-tree .ant-tree-treenode.drag-over-gap-top > [draggable] {
  border-top-color: #1890ff;
}
.ant-tree .ant-tree-treenode.drag-over-gap-bottom > [draggable] {
  border-bottom-color: #1890ff;
}
.ant-tree-show-line {
  /* Motion should hide line of measure */
}
.ant-tree-show-line .ant-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  position: absolute;
  top: calc(100% - 4px);
  right: -12px;
  bottom: -28px;
  border-right: 1px solid #d9d9d9;
  content: "";
}
.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  right: auto;
  left: -12px;
  border-right: none;
  border-left: 1px solid #d9d9d9;
}
.ant-tree-show-line .ant-tree-indent-unit-end::before {
  display: none;
}
.ant-tree-show-line
  .ant-tree-treenode-motion:not(.ant-motion-collapse-leave):not(.ant-motion-collapse-appear-active)
  .ant-tree-indent-unit::before {
  display: none;
}
.ant-tree-show-line .ant-tree-switcher {
  z-index: 1;
  background: #fff;
}
@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-select-tree-checkbox {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #1890ff;
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: "";
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),
    opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: " ";
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: " ";
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked
  .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
  animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
  animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover
  .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-select-tree-checkbox-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: " ";
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled
  .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree-select-dropdown {
  padding: 8px 4px 0;
}
.ant-tree-select-dropdown-rtl {
  direction: rtl;
}
.ant-tree-select-dropdown .ant-select-tree {
  border-radius: 0;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.ant-tree-select-dropdown
  .ant-select-tree-list-holder-inner
  .ant-select-tree-treenode {
  padding-bottom: 8px;
}
.ant-tree-select-dropdown
  .ant-select-tree-list-holder-inner
  .ant-select-tree-treenode
  .ant-select-tree-node-content-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}
.ant-select-tree {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  background: #fff;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.ant-select-tree-rtl {
  direction: rtl;
}
.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: #e6f7ff;
}
.ant-select-tree-list-holder-inner {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.ant-select-tree.ant-select-tree-block-node
  .ant-select-tree-list-holder-inner
  .ant-select-tree-node-content-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}
.ant-select-tree .ant-select-tree-treenode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-select-tree .ant-select-tree-treenode-rtl {
  direction: rtl;
}
.ant-select-tree
  .ant-select-tree-treenode-disabled
  .ant-select-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree
  .ant-select-tree-treenode-disabled
  .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree
  .ant-select-tree-treenode-active
  .ant-select-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-select-tree-indent {
  -ms-flex-item-align: stretch;
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-select-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-select-tree .ant-select-tree-switcher {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}
.ant-select-tree .ant-select-tree-switcher .ant-tree-switcher-icon,
.ant-select-tree .ant-select-tree-switcher .ant-select-tree-switcher-icon {
  font-size: 10px;
  display: inline-block;
  font-weight: bold;
}
.ant-select-tree .ant-select-tree-switcher .ant-tree-switcher-icon svg,
.ant-select-tree .ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-tree .ant-select-tree-switcher-noop {
  cursor: default;
}
.ant-select-tree
  .ant-select-tree-switcher_close
  .ant-select-tree-switcher-icon
  svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.ant-select-tree-rtl.ant-select-tree
  .ant-select-tree-switcher_close
  .ant-select-tree-switcher-icon
  svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.ant-select-tree .ant-select-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-select-tree .ant-select-tree-node-content-wrapper {
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-select-tree
  .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #bae7ff;
}
.ant-select-tree
  .ant-select-tree-node-content-wrapper
  .ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-select-tree
  .ant-select-tree-node-content-wrapper
  .ant-select-tree-iconEle:empty {
  display: none;
}
.ant-select-tree .ant-select-tree-treenode-loading .ant-select-tree-iconEle {
  display: none;
}
.ant-select-tree-node-content-wrapper[draggable="true"] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-select-tree .ant-select-tree-treenode.drag-over > [draggable] {
  color: white;
  background-color: #1890ff;
  opacity: 0.8;
}
.ant-select-tree .ant-select-tree-treenode.drag-over-gap-top > [draggable] {
  border-top-color: #1890ff;
}
.ant-select-tree .ant-select-tree-treenode.drag-over-gap-bottom > [draggable] {
  border-bottom-color: #1890ff;
}
.ant-select-tree-show-line {
  /* Motion should hide line of measure */
}
.ant-select-tree-show-line .ant-select-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit::before {
  position: absolute;
  top: calc(100% - 4px);
  right: -12px;
  bottom: -28px;
  border-right: 1px solid #d9d9d9;
  content: "";
}
.ant-select-tree-rtl.ant-select-tree-show-line
  .ant-select-tree-indent-unit::before {
  right: auto;
  left: -12px;
  border-right: none;
  border-left: 1px solid #d9d9d9;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit-end::before {
  display: none;
}
.ant-select-tree-show-line
  .ant-select-tree-treenode-motion:not(.ant-motion-collapse-leave):not(.ant-motion-collapse-appear-active)
  .ant-select-tree-indent-unit::before {
  display: none;
}
.ant-select-tree-show-line .ant-select-tree-switcher {
  z-index: 1;
  background: #fff;
}
.ant-tree-select-dropdown-rtl
  .ant-select-tree
  .ant-select-tree-switcher_close
  .ant-select-tree-switcher-icon
  svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative;
}
.ant-tree.ant-tree-directory .ant-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  content: "";
  pointer-events: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: #f5f5f5;
}
.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-tree.ant-tree-directory
  .ant-tree-treenode
  .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory
  .ant-tree-treenode
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #1890ff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory
  .ant-tree-treenode-selected
  .ant-tree-node-content-wrapper {
  color: #fff;
  background: transparent;
}
.ant-tree-checkbox {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #1890ff;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: "";
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),
    opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: " ";
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: " ";
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked
  .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
  animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
  animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: " ";
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled
  .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  background: #fff;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.ant-tree-rtl {
  direction: rtl;
}
.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #e6f7ff;
}
.ant-tree-list-holder-inner {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.ant-tree.ant-tree-block-node
  .ant-tree-list-holder-inner
  .ant-tree-node-content-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}
.ant-tree .ant-tree-treenode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-tree .ant-tree-treenode-rtl {
  direction: rtl;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-tree-indent {
  -ms-flex-item-align: stretch;
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-tree .ant-tree-switcher {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}
.ant-tree .ant-tree-switcher .ant-tree-switcher-icon,
.ant-tree .ant-tree-switcher .ant-select-tree-switcher-icon {
  font-size: 10px;
  display: inline-block;
  font-weight: bold;
}
.ant-tree .ant-tree-switcher .ant-tree-switcher-icon svg,
.ant-tree .ant-tree-switcher .ant-select-tree-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree .ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.ant-tree-rtl.ant-tree .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.ant-tree .ant-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-tree .ant-tree-node-content-wrapper {
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
/* Custom change */
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #bae7ff;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none;
}
.ant-tree .ant-tree-treenode-loading .ant-tree-iconEle {
  display: none;
}
.ant-tree-node-content-wrapper[draggable="true"] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-tree .ant-tree-treenode.drag-over > [draggable] {
  color: white;
  background-color: #1890ff;
  opacity: 0.8;
}
.ant-tree .ant-tree-treenode.drag-over-gap-top > [draggable] {
  border-top-color: #1890ff;
}
.ant-tree .ant-tree-treenode.drag-over-gap-bottom > [draggable] {
  border-bottom-color: #1890ff;
}
.ant-tree-show-line {
  /* Motion should hide line of measure */
}
.ant-tree-show-line .ant-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  position: absolute;
  top: calc(100% - 4px);
  right: -12px;
  bottom: -28px;
  border-right: 1px solid #d9d9d9;
  content: "";
}
.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  right: auto;
  left: -12px;
  border-right: none;
  border-left: 1px solid #d9d9d9;
}
.ant-tree-show-line .ant-tree-indent-unit-end::before {
  display: none;
}
.ant-tree-show-line
  .ant-tree-treenode-motion:not(.ant-motion-collapse-leave):not(.ant-motion-collapse-appear-active)
  .ant-tree-indent-unit::before {
  display: none;
}
.ant-tree-show-line .ant-tree-switcher {
  z-index: 1;
  background: #fff;
}
