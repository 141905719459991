/* Import from google fonts */
/* @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap" rel="stylesheet'); */

/* Manually add fonts */
/* @font-face {
	font-family: 'inter';
	src: local('inter'), url(./fonts/inter.scss) format('scss');
  } */

body {
	font-family: 'inter' !important;
	/* font-family: 'Manrope', sans-serif !important; */
	/* font-family: 'Nunito SemiBold' !important; */
	/* font-family: 'Manrope' 'Lato', 'Open Sans', 'Microsoft Yahei', '微软雅黑', STXihei,
		'华文细黑', 'Hiragino Kaku Gothic Pro', Osaka, 'ＭＳ Ｐゴシック',
		'MS PGothic', 'メイリオ', Meiryo, 'Geneva CY', 'Lucida Grande',
		'Arial Unicode MS', 'Helvetica Neue', 'Arial', Sans-Serif !important; */
	// background-color: #23272A !important;
}

html,
body,
#root,
#root > div {
	height: 100%;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/

.react-images__view-image {
    max-height: 100vh !important;
	min-height: 100px;
	min-width: 100px
}

/* fallback with limited opacity for good text contrast */
.box{
   background-color: rgba(255,255,255,0.9); 
}

/* progressive enhancement with blur */
@supports ( backdrop-filter: blur(20px) ) {
   .box {
       background-color: rgba(255,255,255,0.8);
       backdrop-filter: saturate(180%) blur(20px)
   }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
	display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
	display: none;
}
// body {
// 	line-height: 1;
// }
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* UROS EDIT */
input,
textarea,
button,
select {
	outline: none;
}

// div {
// 	font-size: 14px;
// }

span {
	font-size: 14px;
}

input, textarea, select { font-family:inherit; }

a {
	font-weight: 600 !important;
}

// h2,
// .h2 {
// 	display: block;
//     font-size: 1.5em !important;
//     margin-block-start: 0.83em !important;
//     margin-block-end: 0.83em !important;
//     margin-inline-start: 0px !important;
//     margin-inline-end: 0px !important;
// 	font-weight: bold;
// 	margin-top: 8px!important;
// 	margin-bottom: 8px!important;
// 	line-height: 20px !important;
// }

// h3,
// .h3 {
//     display: block;
//     font-size: 1.17em !important;
//     margin-block-start: 1em !important;
//     margin-block-end: 1em !important;
//     margin-inline-start: 0px !important;
//     margin-inline-end: 0px !important;
// 	font-weight: bold;
// 	margin-top: 8px!important;
// 	margin-bottom: 8px!important;
// 	line-height: 20px !important;
// }

/* MODAL STYLE */
.Modal {
	position: absolute;
	top: 40px;
	left: 40px;
	right: 40px;
	bottom: 40px;
	background-color: papayawhip;
}

.Overlay {
	/* position: absolute; */
    /* width: 100%; */
    /* height: 100%; */
    /* z-index: 1099; */
    /* overflow: hidden; */
    /* padding-top: 80px; */
    /* padding-bottom: 80px; */
    /* box-sizing: border-box; */
	/* background-color: rgba(0,0,0,0.4); */
	
	z-index: 9999;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.64);
}

.OverlayWithoutBackground {
	/* position: absolute; */
    /* width: 100%; */
    /* height: 100%; */
    /* z-index: 1099; */
    /* overflow: hidden; */
    /* padding-top: 80px; */
    /* padding-bottom: 80px; */
    /* box-sizing: border-box; */
	/* background-color: rgba(0,0,0,0.4); */
	
	z-index: 9999;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	// background-color: rgba(0, 0, 0, 0.64);
}

.CookieConsent {
	height: 54px !important;
}

@media screen and (max-width: 460px) {
	.CookieConsent {
		height: 120px !important;
	}
}

/* Adjust the link element within the Crisp chatbox */
#crisp-chatbox a[data-is-failure="false"] {
	bottom: 70px !important;
}

.crisp-client .cc-1brb6 .cc-1yy0g .cc-1no03[data-visible=true] {
	// max-height: 70% !important;
	bottom: 140px !important;
}

#crisp-chatbox a[data-is-failure="false"] span[data-id="new_messages"]  {
	bottom: 150px !important;
}

.global-tooltip {
    padding: 4px 8px !important;
    border-radius: 4px !important;
    font-size: 12px !important;
    opacity: 1 !important;
    z-index: 999999 !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
    position: fixed !important;
    height: auto !important;
    line-height: 1.4 !important;
    
    &.__react_component_tooltip {
        position: fixed !important;
        height: auto !important;
        
        &.place-top {
            &:after {
                border-top-color: rgba(0, 0, 0, 0.8) !important;
            }
        }
        &.place-bottom {
            &:after {
                border-bottom-color: rgba(0, 0, 0, 0.8) !important;
            }
        }
        &.place-left {
            &:after {
                border-left-color: rgba(0, 0, 0, 0.8) !important;
            }
        }
        &.place-right {
            &:after {
                border-right-color: rgba(0, 0, 0, 0.8) !important;
            }
        }
    }
}