.logo {
    display: flex;
    justify-content: center;
	margin: 40px auto 0 auto;
	flex-direction: column;
	align-items: center;
	max-width: 370px;
}

.card {
	max-width: 370px;
	min-width: 320px;
	margin: 30px auto 20px auto;
	padding: 0 10px;
}

.auth-form {
	background-color: #fff;
	border: 1px solid #ddd;
	-webkit-box-shadow: 0 1px 3px rgba(50, 50, 50, 0.08);
	box-shadow: 0 1px 3px rgba(50, 50, 50, 0.08);
	border-radius: 16px;
	font-size: 16px;
}

.auth-form.login {
	height: 230px;
}

.auth-form .row {
	// height: 60px;
	padding: 0 15px;
	margin: 0;
	border-bottom: 1px solid #eee;
	display: flex;
	flex-direction: column;
	// background-color: yellow
}

.auth-form .row.name input,
.auth-form .row.email input,
.auth-form .row.password input,
.auth-form .row.confirmation input {
	flex: 1;
	border: 0 none;
	font-size: 16px;
	line-height: 15px;
	padding: 20px 8px;
	color: #666;
}

.link.forgot-password {
	display: block;
	text-align: center;
    font-size: 14px;
    margin-bottom: 14px
}

.link.signup {
    display: block;
    text-align: center;
    margin-top: 10px;
    padding: 10px;
    font-size: 15px;
    color: #666;
}

.auth-form .row.submit {
	border-bottom: none;
	margin: 14px 0;
}

.button.blue {
    cursor: pointer;
    font-weight: bold;
	border: 0 none;
    background-color: #2b88d9;
    border-radius: 8px;
	color: #fff;
	height: 40px;
	font-size: 16px;
	align-items: center;
	line-height: 16px;
}

.button.blue:hover {
    background-color: #4094dd;
}

.link.forgot-password a, .link.signup a, .link.login a {
    color: #1b7edf;
    text-decoration: none;
}

a {
    font-weight: bold;
    color: #2b88d9;
    text-decoration: none;
}

.error {
    margin-bottom: 10px;
    margin-left: 8px;
    color: #c71554;
}