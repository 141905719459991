// TODO style it like this
// https://stackoverflow.com/questions/34850327/styling-input-range-for-webkit-with-pure-css
// https://stackoverflow.com/questions/18389224/how-to-style-html5-range-input-to-have-different-color-before-and-after-slider
// https://freefrontend.com/css-range-sliders/

/*Chrome*/
// @media screen and (-webkit-min-device-pixel-ratio: 0) {
// 	input[type='range'] {
// 		overflow: hidden;
// 		width: 100%;
// 		height: 16px;
// 		-webkit-appearance: none;
// 		border-radius: 50px;
// 	}

// 	input[type='range']::-webkit-slider-runnable-track {
// 		width: 200px;
// 		height: 16px;
// 		// background: linear-gradient(to right, #777, #777), #cbcbcb;
// 		background-size: 5% 100%;
// 		background-repeat: no-repeat;
// 		border-radius: 5px;
// 	}

// 	input[type='range']::-webkit-slider-thumb {
// 		height: 16px;
// 		width: 16px;
// 		-webkit-appearance: none;
// 		margin-top: 0px;
// 		cursor: pointer;
// 		// background: #434343;
// 		// box-shadow: -120px 0 0 115px rgba(35, 39, 42, .2);
// 		border-radius: 50%;
// 		border: 2px solid white;
// 	}
// }
// /** FF*/
// input[type='range']::-moz-range-progress {
// 	background-color: #43e5f7;
// }
// input[type='range']::-moz-range-track {
// 	background-color: #9a905d;
// }
// /* IE*/
// input[type='range']::-ms-fill-lower {
// 	background-color: #43e5f7;
// }
// input[type='range']::-ms-fill-upper {
// 	background-color: #9a905d;
// }

// FROM HERE https://www.cssportal.com/style-input-range/
input[type=range] {
	height: 24px;
	-webkit-appearance: none;
	margin: 0;
	width: 100%;
  }
  input[type=range]:focus {
	outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 8px;
	cursor: pointer;
	animate: 0.2s;
	box-shadow: 0px 0px 0px #000000;
	background: #E3E3E3;
	border-radius: 50px;
	border: 0px solid #000000;
  }
  input[type=range]::-webkit-slider-thumb {
	box-shadow: 0px 0px 0px #000000;
	border: 0px solid #2497E3;
	height: 16px;
	width: 16px;
	border-radius: 50px;
	background: #598BFF;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -5px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
	background: #E3E3E3;
  }
  input[type=range]::-moz-range-track {
	width: 100%;
	height: 8px;
	cursor: pointer;
	animate: 0.2s;
	box-shadow: 0px 0px 0px #000000;
	background: #E3E3E3;
	border-radius: 50px;
	border: 0px solid #000000;
  }
  input[type=range]::-moz-range-thumb {
	box-shadow: 0px 0px 0px #000000;
	border: 0px solid #2497E3;
	height: 16px;
	width: 16px;
	border-radius: 50px;
	background: #598BFF;
	cursor: pointer;
  }
  input[type=range]::-ms-track {
	width: 100%;
	height: 8px;
	cursor: pointer;
	animate: 0.2s;
	background: transparent;
	border-color: transparent;
	color: transparent;
  }
  input[type=range]::-ms-fill-lower {
	background: #E3E3E3;
	border: 0px solid #000000;
	border-radius: 100px;
	box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-fill-upper {
	background: #E3E3E3;
	border: 0px solid #000000;
	border-radius: 100px;
	box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-thumb {
	margin-top: 1px;
	box-shadow: 0px 0px 0px #000000;
	border: 0px solid #2497E3;
	height: 16px;
	width: 16px;
	border-radius: 50px;
	background: #598BFF;
	cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
	background: #E3E3E3;
  }
  input[type=range]:focus::-ms-fill-upper {
	background: #E3E3E3;
  }