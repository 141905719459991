// TODO style it like this
// https://stackoverflow.com/questions/34850327/styling-input-range-for-webkit-with-pure-css
// https://stackoverflow.com/questions/18389224/how-to-style-html5-range-input-to-have-different-color-before-and-after-slider
// https://freefrontend.com/css-range-sliders/

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
	input[type='range'] {
		overflow: hidden;
		width: 210px;
		height: 16px;
		-webkit-appearance: none;
		border-radius: 50px;
	}

	input[type='range']::-webkit-slider-runnable-track {
		width: 200px;
		height: 16px;
		background: linear-gradient(to right, #777, #777), #cbcbcb;
		background-size: 5% 100%;
		background-repeat: no-repeat;
		border-radius: 5px;
	}

	input[type='range']::-webkit-slider-thumb {
		height: 16px;
		width: 16px;
		-webkit-appearance: none;
		margin-top: 0px;
		cursor: pointer;
		background: #434343;
		box-shadow: -100px 0 0 95px #7b7b7b;
		border-radius: 50%;
		border: 2px solid white;
	}
}
/** FF*/
input[type='range']::-moz-range-progress {
	background-color: #43e5f7;
}
input[type='range']::-moz-range-track {
	background-color: #9a905d;
}
/* IE*/
input[type='range']::-ms-fill-lower {
	background-color: #43e5f7;
}
input[type='range']::-ms-fill-upper {
	background-color: #9a905d;
}
